import { css } from 'styled-components';
import { above } from '../abstracts/breakpoints';

const typography = css`
  html {
    font-size: 62.5%;
  }

  body {
    font-size: ${props => props.theme.font.size.base};
    font-family: ${props => props.theme.font.family.base};
    color: ${props => props.theme.color.blue.navy};
  }

  *::selection {
    color: #ffffff;
    background: ${props => props.theme.color.blue.navy};
    // background: rgba(32, 40, 70, 0.6); // navy
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    &:not(:last-child) {
      margin-bottom: 0.75em;
    }
    &:not(:first-child) {
      margin-top: 1.75em;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    margin-top: 0;
    margin-bottom: 0.75em;
  }

  p {
    line-height: 1.9;
    font-size: 1.8rem;
    margin-bottom: 2em;
  }
  a {
    text-decoration: none;
    &:visited {
      color: initial;
    }
  }
`;

export default typography;
