/**
 * A component that renders image from the _rawComponentBlock in
 * the sanity API
 */

import React from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import { getFixedGatsbyImage } from 'gatsby-source-sanity';
import { get } from 'lodash';
import sanityConfig from '../utils/sanityClientConfig';

const FixedImageFromRaw = ({
  asset,
  wrapperStyle = {},
  imgStyle = {},
  width,
  height,
  alt = '',
  className,
}) => {
  const assetId = get(asset, '_id') || get(asset, 'id');
  const assetRef = get(asset, '_ref') || get(asset, 'ref');
  if (!assetId && !assetRef) {
    return null;
  }

  const id = assetId || assetRef;
  const fixedProps = getFixedGatsbyImage(id, { width, height }, sanityConfig);

  return (
    <Image
      className={className}
      style={wrapperStyle}
      imgStyle={imgStyle}
      fixed={fixedProps}
      alt={alt}
    />
  );
};

export default FixedImageFromRaw;
