// for ThemeProvider. Define global variables here
const theme = {
  font: {
    size: { base: '16px' },
    family: {
      base: 'Sofia Pro, Helvetica Neue, Helvetica,Arial, Sans-Serif',
    },
  },
  color: {
    blue: {
      dark: '#0056AF',
      light: '#51BFFF',
      lightNavy: '#202846',
      darkNavy: '#1c233e',
      primary: '#1974D3',
      navy: '#202846',
    },
    grey: {
      dark: '#333333',
      light: '#A1A1A1',
      medium: '#DBDBDB',
      lighter: '#EEEEEE',
      superLight: '#F8F8F8',
    },
    white: '#FFF',
    green: '#30D998',
    yellow: '#FFC200',
    red: '#dc3545',
  },
  transition: {
    duration: {
      base: '300ms',
    },
    timing: {
      base: 'ease-in-out',
    },
  },
  shadow: {
    base: '0 2px 6px 0 rgba(0,0,0,.4)',
  },
};

export default theme;
