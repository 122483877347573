import React from 'react';
import styled, { css } from 'styled-components';
import { above } from '../styles';

const StyledContainer = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  //overflow: auto;

  @media ${above.sm} {
    margin-left: 50px;
    margin-right: 50px;
  }

  @media ${above.lg} {
    margin-left: 90px;
    margin-right: 90px;
  }

  @media ${above.xxl} {
    margin-left: 230px;
    margin-right: 230px;
  }
`;

const Container = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

export default Container;
