import React from 'react';
import styled from 'styled-components';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { below } from '../styles';

const Text = styled.span`
  margin-bottom: 0;
  color: ${props =>
    props.darkMode ? props.theme.color.blue.navy : props.theme.color.white};
  line-height: 1.38;
  font-size: 1.8rem;
  @media ${below.md} {
    font-size: 1.6rem;
  }
`;

const StyledLink = styled(AnchorLink)`
  display: inline-flex;
  ${props => (props.center ? 'justify-content: center;' : '')}
  align-items: center;
  &:hover {
    &:after {
      width: 0;
    }
    svg {
      transform: translateX(5px);
      transition: transform 300ms ease;
    }
  }
`;

const StyledExternalLink = styled.a`
  display: inline-flex;
  ${props => (props.center ? 'justify-content: center;' : '')}
  align-items: center;
  &:hover {
    &:after {
      width: 0;
    }
    svg {
      transform: translateX(5px);
      transition: transform 300ms ease;
    }
  }
`;

const ArrowRight = styled.svg`
  display: inline-block;
  vertical-align: middle;
  margin-left: 12px;
  transition: transform 300ms ease;
  path {
    fill: ${props =>
      props.darkMode ? props.theme.color.blue.navy : props.theme.color.white};
  }
`;

// set "center" to true if you want the link to center
const CTALink = ({ className, url, text, center, darkMode = false }) => {

return(
  <span>
    {url.includes('www.') ? (
     <StyledExternalLink className={className} center={center} href={url} title={text}>
      <Text darkMode={darkMode}>{text}</Text>
      <ArrowRight
        darkMode={darkMode}
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M21.7071 8.70711C22.0976 8.31658 22.0976 7.68342 21.7071 7.29289L15.3431 0.928932C14.9526 0.538408 14.3195 0.538408 13.9289 0.928932C13.5384 1.31946 13.5384 1.95262 13.9289 2.34315L19.5858 8L13.9289 13.6569C13.5384 14.0474 13.5384 14.6805 13.9289 15.0711C14.3195 15.4616 14.9526 15.4616 15.3431 15.0711L21.7071 8.70711ZM0.5 9H21V7H0.5V9Z" />
      </ArrowRight>
    </StyledExternalLink>) 
    : 
   (<StyledLink className={className} center={center} to={url} title={text}>
      <Text darkMode={darkMode}>{text}</Text>
      <ArrowRight
        darkMode={darkMode}
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M21.7071 8.70711C22.0976 8.31658 22.0976 7.68342 21.7071 7.29289L15.3431 0.928932C14.9526 0.538408 14.3195 0.538408 13.9289 0.928932C13.5384 1.31946 13.5384 1.95262 13.9289 2.34315L19.5858 8L13.9289 13.6569C13.5384 14.0474 13.5384 14.6805 13.9289 15.0711C14.3195 15.4616 14.9526 15.4616 15.3431 15.0711L21.7071 8.70711ZM0.5 9H21V7H0.5V9Z" />
      </ArrowRight>
    </StyledLink>)}
  </span>
)}
export default CTALink;
