// This is to render paragraph defined in simpleText schema
// Content will be rendered in p tag only

import React from 'react';
import BlockContent from '@sanity/block-content-to-react';

// helper function to render paragraph
const renderParagraph = ({ children }) => <p>{children}</p>;

const Paragraph = ({ text }) => (
  <BlockContent
    blocks={text}
    serializers={{
      marks: {
        p: renderParagraph,
      },
    }}
  />
);

export default Paragraph;
