import { css, createGlobalStyle } from 'styled-components';
// normalize.css for styled components
import { normalize } from 'styled-normalize';
import { layout, list, typography } from './base';
import { above, below, BREAKPOINTS } from './abstracts/breakpoints';

/**
 * Add new CSS modules here, and it will be used to create
 * global styles using createGlobalStyle API.
 * This is needed so that each module can have access
 * to the theme props being used in the ThemeProvider.
 */
const allStylesModules = css`
  ${normalize}
  ${typography}
  ${layout}
  ${list}
`;

const GlobalStyle = createGlobalStyle`
  ${allStylesModules}
`;

export { above, below, GlobalStyle, BREAKPOINTS };
