import { css } from 'styled-components';

export default css`
  ul,
  ol {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
`;
