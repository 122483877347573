// Square with a navy blue background color
import React from 'react';
import styled, { css } from 'styled-components';
import { above } from '../styles';

const StyledSquare = styled.div`
  background-color: ${props => props.theme.color.blue.navy};
`;

const Square = () => <StyledSquare />;

export default Square;
