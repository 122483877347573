/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const SEO = ({ seo }) => {
  const metaTitle = seo.title || '';
  const metaTwitterHandle = seo.seoTwitterHandle;
  const metaDescription = seo.seoDescription || '';
  const metaKeywords = seo.seoKeywords || [];
  const metaImage = seo.image;
  const metaUrl = seo.url || '';

  return (
    <>
      <Helmet
        title={metaTitle}
        meta={[
          {
            name: 'description',
            content: metaDescription,
          },
          {
            name: 'keywords',
            content: metaKeywords,
          },
          {
            name: 'image',
            content: metaImage,
          },
          {
            name: 'twitter:card',
            content: metaTitle,
          },
          {
            name: 'twitter:creator',
            content: metaTwitterHandle,
          },
          {
            name: 'twitter:title',
            content: metaTitle,
          },
          {
            name: 'twitter:description',
            content: metaDescription,
          },
          {
            name: 'twitter:image',
            content: metaImage,
          },
          {
            property: 'og:url',
            content: metaUrl,
          },
          {
            property: 'og:title',
            content: metaTitle,
          },
          {
            property: 'og:description',
            content: metaDescription,
          },
          {
            property: 'og:image',
            content: metaImage,
          },
        ]}
        link={[
          {
            rel: 'canonical',
            href: metaUrl,
          },
        ]}
      >
        <script src="https://cdn.polyfill.io/v2/polyfill.min.js" />
      </Helmet>
    </>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
};
