import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 0;
`;

const StyledSVG = styled.svg`
  float: ${props => props.float};
`;

const ProgressCircle = props => {
  const { size, strokeWidth, stroke, value, max, float } = props;

  const radius = (size - strokeWidth) / 2;
  const viewBox = `0 0 ${size} ${size}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - (dashArray * value) / max;

  return (
    <Wrapper>
      <StyledSVG width={size} height={size} viewBox={viewBox} float={float}>
        <circle
          fill="none"
          stroke="#F6F6F6"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          fill="none"
          stroke={stroke}
          strokeLinecap="butt"
          strokeLinejoin="butt"
          strokeDasharray={dashArray}
          strokeDashoffset={dashOffset}
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </StyledSVG>
    </Wrapper>
  );
};

export default ProgressCircle;
