/**
 * A component that renders image from the _rawComponentBlock in
 * the sanity API
 */

import React from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import { getFluidGatsbyImage } from 'gatsby-source-sanity';
import { get } from 'lodash';

import sanityConfig from '../utils/sanityClientConfig';

const FluidImageFromRaw = ({
  asset,
  maxWidth = 1024,
  wrapperStyle = {},
  imgStyle = {},
  alt = '',
  className,
  aspectRatio,
  ...props
}) => {
  const assetId = get(asset, '_id') || get(asset, 'id');
  const assetRef = get(asset, '_ref') || get(asset, 'ref');

  if (!assetId && !assetRef) {
    return null;
  }

  const id = assetId || assetRef;

  const fluidProps = getFluidGatsbyImage(id, { maxWidth }, sanityConfig);

  if (aspectRatio) fluidProps.aspectRatio = Number(aspectRatio);

  return (
    <Image
      className={className}
      wrappreStyle={wrapperStyle}
      style={{ width: '100%', ...wrapperStyle }}
      imgStyle={imgStyle}
      fluid={fluidProps}
      alt={alt}
      objectFit="cover"
      objectPosition="50% 50%"
      {...props}
    />
  );
};

export default FluidImageFromRaw;
