import { css } from 'styled-components';
import theme from '../abstracts/theme';

export default css`
  html {
    box-sizing: border-box;
  }

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }

  /**
    Don't add height: 100% to html or body. It will mess up Framer Motion animations
    specifically the useViewportScroll hook
  */
  html {
    background-color: ${theme.color.green};
  }
  body {
    //background-color: ${theme.color.white};
    background-color: ${theme.color.green};
    -webkit-overflow-scrolling: touch;
    margin: 0;
  }

  main {
    position: relative;
    z-index: 1;
    background-color: ${theme.color.white};
  }

  #___gatsby {
    background-color: ${theme.color.white};
  }

  button {
    border: none;
    background-color: transparent;
    outline: none;

    /** Ring style focus state */
    &:focus {
      box-shadow: 0 0 0 3px inherit;
    }
  }
`;
