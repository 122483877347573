export const BREAKPOINTS = {
  xxxs: 350,
  xxs: 400,
  xs: 500,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1400,
  xxl: 1800,
  xxxl: 1920,
  xxxxl: 2560,
  xxxxxl: 3440,
  fm: 1000,
};

export const above = {
  xxxs: `(min-width: ${BREAKPOINTS.xxxs}px)`,
  xxs: `(min-width: ${BREAKPOINTS.xxs}px)`,
  xs: `(min-width: ${BREAKPOINTS.xs}px)`,
  sm: `(min-width: ${BREAKPOINTS.sm}px)`,
  md: `(min-width: ${BREAKPOINTS.md}px)`,
  lg: `(min-width: ${BREAKPOINTS.lg}px)`,
  xl: `(min-width: ${BREAKPOINTS.xl}px)`,
  xxl: `(min-width: ${BREAKPOINTS.xxl}px)`,
  xxxl: `(min-width: ${BREAKPOINTS.xxxl}px)`,
  xxxxl: `(min-width: ${BREAKPOINTS.xxxxl}px)`,
  xxxxxl: `(min-width: ${BREAKPOINTS.xxxxxl}px)`,
  fm: `(min-width: ${BREAKPOINTS.fm}px)`,
};

export const below = {
  xxxs: `(max-width: ${BREAKPOINTS.xxxs - 1}px)`,
  xxs: `(max-width: ${BREAKPOINTS.xxs - 1}px)`,
  xs: `(max-width: ${BREAKPOINTS.xs - 1}px)`,
  sm: `(max-width: ${BREAKPOINTS.sm - 1}px)`,
  md: `(max-width: ${BREAKPOINTS.md - 1}px)`,
  lg: `(max-width: ${BREAKPOINTS.lg - 1}px)`,
  xl: `(max-width: ${BREAKPOINTS.xl - 1}px)`,
  xxl: `(max-width: ${BREAKPOINTS.xxl - 1}px)`,
  xxxl: `(max-width: ${BREAKPOINTS.xxxl - 1}px)`,
  xxxxl: `(max-width: ${BREAKPOINTS.xxxxl - 1}px)`,
  xxxxxl: `(max-width: ${BREAKPOINTS.xxxxxl - 1}px)`,
  fm: `(max-width: ${BREAKPOINTS.fm - 1}px)`,
};
